export default {
  props: {
    questionId: {
      required: false,
      type: String,
      default: null
    },
    choices: {
      required: false,
      type: Array,
    },
    isReadSurvey: {
      required: false,
      type: Boolean,
      default: false,
    },
    options: {
      required: false,
    },
    value: {
      required: false,
    },
  },

  methods: {
    onUpdateValue (newValue) {
      this.$emit('update-value', newValue)
    },
  },
}

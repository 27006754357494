<template>
  <div>
    <p>entre...</p>
    <v-time-picker :value="interval.start"
                   format="24hr"
                   :landscape="$vuetify.breakpoint.mdAndUp"
                   full-width
                   :disabled="isReadSurvey || options.readonly"
                   @input="onUpdateOneValue('start', $event)"
    />
    <p>...et...</p>
    <v-time-picker :value="interval.end"
                   format="24hr"
                   :landscape="$vuetify.breakpoint.mdAndUp"
                   full-width
                   :disabled="isReadSurvey || options.readonly"
                   @input="onUpdateOneValue('end', $event)"
    />
  </div>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'SurveyQuestionTimeInterval',

  mixins: [questionMixins],

  data: () => ({
    interval: {
      start: null,
      end: null,
    },
  }),

  mounted () {
    if (this.value) {
      const [start, end] = this.value.split('/')
      this.interval.start = start
      this.interval.end = end
    }
  },

  methods: {
    onUpdateOneValue (cursor, newValue) {
      this.interval[cursor] = newValue

      if (this.interval.start && this.interval.end) {
        this.onUpdateValue(`${this.interval.start}/${this.interval.end}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  font-size: 20px;
}
</style>
